<template>
  <section class="login-2">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg" style="background-color: #fff;">
          <div class="row flex-grow">
            <div class="col-lg-6 d-flex align-items-center justify-content-center">
              <div class="auth-form-transparent text-left p-3">
                <div class="brand-logo">
                  <img src="@/assets/images/logo.png" alt="image">
                </div>
                <h4>Welcome back!</h4>
                <h6 class="font-weight-light">Happy to see you again!</h6>
                <form class="pt-3" @submit.prevent="login">
                  <div class="form-group"><label for="exampleInputEmail">Username</label>
                    <div class="input-group">
                      <div class="input-group-prepend bg-transparent"><span
                          class="input-group-text bg-transparent border-right-0"><i
                            class="mdi mdi-account-outline text-primary"></i></span></div><input type="text"
                        v-model="user.username" required placeholder="Username"
                        class="form-control form-control-lg border-left-0">
                    </div>
                  </div>
                  <div class="form-group"><label for="exampleInputPassword">Password</label>
                    <div class="input-group">
                      <div class="input-group-prepend bg-transparent"><span
                          class="input-group-text bg-transparent border-right-0"><i
                            class="mdi mdi-lock-outline text-primary"></i></span></div><input type="password"
                        v-model="user.password" required placeholder="Password"
                        class="form-control form-control-lg border-left-0">
                    </div>
                  </div>
                  <div class="my-3">
                    <button type="submit"
                      class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">LOGIN</button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 login-half-bg d-flex flex-row">
              <p class="text-white font-weight-medium text-center flex-grow align-self-end">Copyright © <a href="#"
                  target="_blank">Skyline</a> {{ new Date().getFullYear() }}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { contactApi } from "../api";
export default {
  name: 'login',
  components: {
  },
  data() {
    return {
      user: {
        username: '',
        password: '',
      }
    };
  },
  methods: {
    async login() {
      try {
        this.showLoader = true;
        const response = await contactApi.login({
          ...this.user,
        });
        this.showLoader = false;

        // Destructure and add default values to avoid undefined errors
        const { status, token, data = {} } = response;
        if (status === 200) {
          // Store the token in localStorage if it exists
          localStorage.setItem("token", token);
          localStorage.setItem("user", this.user.username);

          // Navigate to the home page
          this.$router.push("/");

          // Display a success notification
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            type: "success",
            title: " ",
            text: "Login successfully",
          });
        } else {
          // Display an error notification if login fails
          this.$swal({
            type: "error",
            title: "Oops...",
            text: "Invalid username or password",
          });
        }
      } catch (error) {
        console.error("Login error:", error);
        // Display a general error notification if there’s a network or server error
        this.$swal({
          type: "error",
          title: "Login Failed",
          text: "An error occurred. Please try again later.",
        });
      }
    }
  }

}
</script>
